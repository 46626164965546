import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { group, groups } from 'd3-array';
import {
  FilterList,
  LocalSelectionCategories,
  SelectFilter,
  SelectionCategories,
  SelectionListIdNames,
  SelectionListItem,
  SelectionListItems,
} from '../../engine/filters.model';
import {
  upsertFilter,
  useSelectionListsValidated,
} from '../../engine/filters.engine';
import { useActiveSelectionListItems } from './utils';
import {
  getNestedId,
  GetNestedIdProps,
  nestSelectionLists,
  TreeApi,
} from '../tree';
import { PopoutTree, PopoutTreeProps } from './PopoutTree';
import { SelectionListControls } from '../SelectionListControls';
import {
  deleteFilter,
  useAllFiltersState,
} from '../../engine/filters.repository';
import styles from './PopoutTreeFilter.module.css';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { PlusMinusIcon } from '../add-entity-button/plus-minus-icon';
import { ADD_ENTITY_BUTTON_CLASS_NAME } from '../filter-chips/filter-chips-toggle/filter-chips-toggle-list';

type SelectionListItemWithSelectionListId = SelectionListItem & {
  selectionListId: SelectionListIdNames;
};

type PopoutTreeFilter = Pick<PopoutTreeProps, 'branches'> & {
  selectionListIds: SelectionCategories[];
  activeFilters: SelectionListItems[];
  minSelections?: number;
  maxSelections?: number;
  filterLabel?: string;
  disallowMultiLevelSelect?: boolean;
  disableLevels?: SelectionCategories[];
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (items: SelectionListItemWithSelectionListId[]) => void;
  className?: string;
};

export const PopoutTreeFilter = ({
  selectionListIds,
  activeFilters,
  branches,
  minSelections = 0,
  maxSelections,
  filterLabel,
  disallowMultiLevelSelect = false,
  disableLevels,
  isOpen,
  onClose,
  onSubmit,
  className,
}: PopoutTreeFilter) => {
  const [selections, setSelections] = useState<string[]>([]);

  const toast = useToast();
  const onChange = (value: string[]) => {
    if (maxSelections && value.length > maxSelections) {
      if (!toast.isActive('max-selections-toast')) {
        toast({
          id: 'max-selections-toast',
          position: 'top-right',
          status: 'warning',
          title: `${filterLabel ? filterLabel : 'Selection'} Limit`,
          description: `You can only choose up to ${maxSelections} ${filterLabel ? filterLabel : 'items'} at a time!`,
          isClosable: true,
          variant: 'subtle',
        });
      }
      return;
    }

    if (disallowMultiLevelSelect) {
      const lastValue = value[value.length - 1];
      if (!lastValue) return;
      const lastValueLength = lastValue.split('_').length;
      setSelections((prevValue) => {
        if (prevValue.every((v) => v.split('_').length === lastValueLength)) {
          return value;
        } else return [lastValue];
      });

      return;
    }

    setSelections(value);
  };

  const selectionLists = useSelectionListsValidated(selectionListIds);

  const sortedSelectionLists = useMemo(() => {
    return selectionLists.map((selectionList) => {
      const activeFilter = activeFilters.find(
        (filter) => filter.id === selectionList.id
      );
      if (!activeFilter) return selectionList;
      else {
        return {
          ...selectionList,
          value: [...selectionList.value].sort((a, b) => {
            const aActive = activeFilter.value.some((item) => item.id === a.id);
            const bActive = activeFilter.value.some((item) => item.id === b.id);
            if (aActive && !bActive) return -1;
            if (!aActive && bActive) return 1;
            return 0;
          }),
        };
      }
    });
  }, [selectionLists, activeFilters]);

  const nestedSelectionTree = useMemo(
    () => nestSelectionLists(sortedSelectionLists),
    [sortedSelectionLists]
  );

  const activeNestedIds = useMemo(
    () =>
      activeFilters
        .flatMap((filter) =>
          filter.value.map((item): GetNestedIdProps['item'] => ({
            ...item,
            selectionListId: filter.id,
          }))
        )
        .map((item) =>
          getNestedId({ selectionLists: sortedSelectionLists, item })
        ),
    [activeFilters, sortedSelectionLists]
  );

  const resetSelections = useCallback(() => {
    setSelections(activeNestedIds);
  }, [activeNestedIds]);
  const popoutTreeRef = useRef<TreeApi>();
  useEffect(() => {
    resetSelections();
    const treeApi = popoutTreeRef.current;
    if (isOpen && treeApi) treeApi.onSearch('');
  }, [isOpen, resetSelections]);

  const onClear = () => {
    setSelections([]);
  };

  const handleSubmit = () => {
    if (selections.length < minSelections) {
      if (!toast.isActive('min-selections-toast')) {
        toast({
          id: 'min-selections-toast',
          position: 'top-right',
          status: 'warning',
          title: `${filterLabel ? filterLabel : 'Selection'} Limit`,
          description: `You must make at least ${minSelections} ${filterLabel ? filterLabel : ''} selection!`,
          isClosable: true,
          variant: 'subtle',
        });
      }
      return;
    }

    const selectedEntities = selections
      .map((id) => {
        const splitIds = id.split('_');
        const splitIdsLength = splitIds.length;
        const lastId = splitIds[splitIdsLength - 1];

        if (!lastId) return null;

        const selectionList = sortedSelectionLists[splitIdsLength - 1];
        const selectedItem = selectionList.value.find(
          (item) => item.id === lastId
        );

        if (!selectedItem) return null;

        return { ...selectedItem, selectionListId: selectionList.id };
      })
      .filter((entity) => entity !== null);

    if (onSubmit) {
      onSubmit(selectedEntities);
    } else {
      const groupedFilters = group(selectedEntities, (d) => d.selectionListId);

      selectionListIds.forEach((selectionListId) => {
        if (!groupedFilters.has(selectionListId)) deleteFilter(selectionListId);
      });

      groupedFilters.forEach((value, selectionListId) => {
        upsertFilter(selectionListId, {
          selectionListId,
          isMulti: true,
          value,
        });
      });
    }

    onClose?.();
  };

  return (
    <>
      <PopoutTree
        data={nestedSelectionTree}
        value={selections}
        {...(disableLevels && {
          disabledLevels: disableLevels.map((selectionId) =>
            selectionListIds.indexOf(selectionId)
          ),
        })}
        ref={popoutTreeRef}
        onChange={onChange}
        branches={branches}
        className={className}
      />
      <SelectionListControls
        onClear={onClear}
        onClose={onClose}
        onSubmit={handleSubmit}
        submitText={activeNestedIds.length ? 'Update' : 'Add'}
      />
    </>
  );
};

export const PrimaryEntityPopoutTreeFilter = (
  props: Pick<
    PopoutTreeFilter,
    | 'selectionListIds'
    | 'filterLabel'
    | 'maxSelections'
    | 'minSelections'
    | 'disableLevels'
    | 'branches'
  > & { activeLimit?: number } & PropsWithChildren
) => {
  const { selectionListIds, activeLimit = 6 } = props;

  const allFilters = useAllFiltersState();
  const activeSelectionListItems =
    useActiveSelectionListItems(selectionListIds);

  const primaryEntities = useMemo(
    () =>
      allFilters
        .filter(
          (filter) => filter.id === LocalSelectionCategories.PRIMARY_ENTITIES
        )
        .filter((filter): filter is SelectFilter<FilterList> =>
          Array.isArray(filter.value)
        )
        .flatMap((filter) => filter.value),
    [allFilters]
  );

  const primaryEntitySelectionList: SelectionListItems[] = useMemo(() => {
    const groupedFilters = groups(primaryEntities, (d) => d.selectionListId);
    return groupedFilters.map(
      ([selectionListId, items]): SelectionListItems => ({
        id: selectionListId as SelectionCategories,
        value: items.map((item) => ({ ...item, id: `${item.id}` })),
      })
    );
  }, [primaryEntities]);

  const onSubmit: PopoutTreeFilter['onSubmit'] = (items) => {
    const newPrimaryEntities: SelectionListItemWithSelectionListId[] =
      items.filter(
        (item) =>
          !primaryEntities.some(
            (entity) =>
              `${entity.selectionListId}_${entity.id}` ===
              `${item.selectionListId}_${item.id}`
          )
      );
    const sortedNewPrimaryEntities = [...items].sort((a) => {
      if (!primaryEntities.some((filter) => filter.id === a.id)) {
        return -1;
      } else return 0;
    });

    upsertFilter(LocalSelectionCategories.PRIMARY_ENTITIES, {
      value: sortedNewPrimaryEntities,
    });

    const filtersToApply = sortedNewPrimaryEntities
      .filter((item) => {
        // If the item is a new primary entity, return true
        if (newPrimaryEntities.some((newEntity) => newEntity.id === item.id)) {
          return true;
        }

        // If the item is currently active, return true
        const itemActiveFilters = activeSelectionListItems.find(
          (activeFilter) => activeFilter.id === item.selectionListId
        );
        if (!itemActiveFilters) return false;
        return itemActiveFilters.value.some(
          (activeItem) => activeItem.id === item.id
        );
      })
      // Only apply the first n filters
      .slice(0, activeLimit);

    selectionListIds.forEach(deleteFilter);

    const groupedFiltersToApply = groups(
      filtersToApply,
      (d) => d.selectionListId
    );
    groupedFiltersToApply.forEach((group) => {
      upsertFilter(group[0], {
        selectionListId: group[0],
        isMulti: true,
        value: group[1],
      });
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          data-testid="primary-filter-add-button"
          variant="addcompany"
          leftIcon={<PlusMinusIcon className={ADD_ENTITY_BUTTON_CLASS_NAME} />}
          display="flex"
          className={ADD_ENTITY_BUTTON_CLASS_NAME}
        >
          {props.children}
        </Button>
      </PopoverTrigger>
      <PopoverContent boxShadow="2xl" borderRadius="md">
        <PopoutTreeFilter
          {...props}
          isOpen={isOpen}
          activeFilters={primaryEntitySelectionList}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </PopoverContent>
    </Popover>
  );
};

export const SecondaryPopoutTreeFilter = (
  props: Pick<
    PopoutTreeFilter,
    | 'selectionListIds'
    | 'maxSelections'
    | 'minSelections'
    | 'disallowMultiLevelSelect'
    | 'filterLabel'
    | 'onClose'
  >
) => {
  const { selectionListIds } = props;
  const activeSelectionListItems =
    useActiveSelectionListItems(selectionListIds);

  return (
    <PopoutTreeFilter
      {...props}
      activeFilters={activeSelectionListItems}
      className={styles.popoutTree}
    />
  );
};
