import { getEntity } from '@ngneat/elf-entities';
import { of, forkJoin, combineLatest, Observable, throwError, iif } from 'rxjs';
import {
  shareReplay,
  catchError,
  switchMap,
  first,
  tap,
  skipUntil,
  debounceTime,
  retry,
  distinctUntilKeyChanged,
  find,
} from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
import { API_ROOT, TempFilterOverrideLookup } from './filters.constants';
import {
  ViewFilterDefaults,
  FilterList,
  GetViewFilterDefaults,
  ViewFilterDefaultsValue$,
  SelectionListIdNames,
  GetViewDefaultMonth,
  DefaultDates,
  FilterName,
  LocalSelectionCategories,
} from './filters.model';
import {
  filterStore,
  getDefaultLastMonth,
  getLastStartDate,
  setDefaultLastMonth,
  setLastStartDate,
  viewFilterDefaultEntitiesRef,
} from './filters.core';
import {
  createOneTimeEffectFn,
  persistFilterStore,
} from './filters.repository';
import {
  FilterSets,
  ViewsForDefaultsOnly,
  ViewTypes,
} from '../data-api/data-api.model';
import { get, isEmpty, isNil, isUndefined } from 'lodash';
import {
  addLoadingStatus,
  DATE_FORMAT_WITH_DAY,
  removeLoadingStatus,
  RevelioGqlClient,
  setLastDateReady,
  STANDARD_DATE_FORMAT,
  toggleStatusOnGlobalLoaderOrSkipOne,
  Views,
} from '@revelio/core';
import {
  AUTH_CHECKED,
  authStore,
  getAuthStoreUser,
  getUserTrialType,
  handleAuthResponse,
} from '@revelio/auth';
import {
  addViewViewTypeDefault,
  createCompactDefaults,
  viewFiltersDefaultsDataSource,
} from './filters.defaults.core';
import { Client } from 'urql';
import { TrialType, User, graphql } from '@revelio/data-access';
import { lagDashboardDateByYear } from '../filter-components/utils/date-utils';

/**
 * View / filter default fetching
 *
 * GET /api/<tab>/<filter>/defaults
 * GET /api/job_analysis_overview/role/defaults
 */
export function fetchViewFilterDefault(
  viewViewType: ViewFilterDefaults['id'],
  filter: FilterName | undefined = undefined,
  view: ViewTypes | undefined = undefined
) {
  let maybeMappedFilter = get(
    TempFilterOverrideLookup,
    filter as SelectionListIdNames,
    filter
  );

  const userHasWebsitePostings = authStore.getValue().user?.linkup_postings;
  if (
    maybeMappedFilter === LocalSelectionCategories.PROVIDER &&
    userHasWebsitePostings
  ) {
    maybeMappedFilter = `wp-${maybeMappedFilter}`;
  }

  const userHasUnifiedPostings = authStore.getValue().user?.unified_postings;
  if (
    maybeMappedFilter === LocalSelectionCategories.PROVIDER &&
    userHasUnifiedPostings
  ) {
    maybeMappedFilter = `unified-${maybeMappedFilter}`;
  }

  const endpointPath = isUndefined(filter)
    ? viewViewType
    : `${viewViewType}/${maybeMappedFilter}`;

  const isSharedDefault = filter == LocalSelectionCategories.PRIMARY_ENTITIES;

  let urlString = '';

  const localDefaultPath = '/assets/filter-defaults';

  if (isSharedDefault) {
    urlString = `${localDefaultPath}/${view}_shared/defaults.json`;
  } else {
    urlString = `${localDefaultPath}/${endpointPath}.json`;
  }

  toggleStatusOnGlobalLoaderOrSkipOne(urlString);
  addLoadingStatus(urlString);
  return fromFetch<FilterList>(urlString, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    selector: (response) => {
      handleAuthResponse(response);
      if (response.status != 200) {
        return Promise.resolve({});
      }
      return response.json();
    },
  }).pipe(
    retry({ count: 3, delay: 3000 }),
    tap(() => {
      toggleStatusOnGlobalLoaderOrSkipOne(urlString);
      removeLoadingStatus(urlString);
    }),
    catchError((e) => {
      console.error('Get Defaults Error:', e);
      toggleStatusOnGlobalLoaderOrSkipOne(urlString);
      removeLoadingStatus(urlString);
      return of<{ error?: any }>({ error: e });
    })
  );
}

export function fetchViewFilterDefaultMonth(dateType: DefaultDates) {
  const endpointPath =
    dateType === DefaultDates.LAST_START_DATE
      ? 'posting/last_start_date'
      : 'overview/last_month';

  const urlString = `${API_ROOT}/api/${endpointPath}/defaults`;

  toggleStatusOnGlobalLoaderOrSkipOne(urlString);
  addLoadingStatus(urlString);
  return fromFetch<FilterList>(urlString, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    selector: (response) => {
      handleAuthResponse(response);
      if (response.status != 200) {
        return Promise.resolve({});
      }
      return response.json();
    },
  }).pipe(
    retry({ count: 3, delay: 3000 }),
    tap(() => {
      toggleStatusOnGlobalLoaderOrSkipOne(urlString);
      removeLoadingStatus(urlString);
    }),
    catchError((e) => {
      console.error('Get Defaults Error:', e);
      toggleStatusOnGlobalLoaderOrSkipOne(urlString);
      removeLoadingStatus(urlString);
      return of<{ error?: any }>({ error: e });
    })
  );
}

const MonthWeekIndexQuery = graphql(`
  query MonthWeekIndexQuery {
    index {
      startMonthIndex
      endMonthIndex
      startWeekIndex
      endWeekIndex
      maxWeekIndex
      endMonth
      maxWeek
    }
  }
`);

export function queryViewFilterDefaultMonth() {
  const loaderKey = `DefaultDates`;

  toggleStatusOnGlobalLoaderOrSkipOne(loaderKey);
  addLoadingStatus(loaderKey);

  return RevelioGqlClient.pipe(
    find((c) => !isNil(c)),
    switchMap(async (client) => {
      const resp = await (client as Client)
        .query(MonthWeekIndexQuery, {})
        .toPromise();

      const lastMonth = get(resp, 'data.index.endMonth') as string;
      const lastStartDate = get(resp, 'data.index.maxWeek') as string;
      if (!lastMonth || !lastStartDate) {
        return new Error(`Hum, we didn't get a date string back.`);
      }

      const user = getAuthStoreUser();
      const hasTrialDataLag =
        !user?.live &&
        getUserTrialType(user as User) === TrialType.ONE_YEAR_DATA_LAG;
      const lookup: { [key in DefaultDates]?: string } = {
        [DefaultDates.DEFAULT_LAST_MONTH]: hasTrialDataLag
          ? lagDashboardDateByYear(lastMonth, STANDARD_DATE_FORMAT)
          : lastMonth,
        [DefaultDates.LAST_START_DATE]: hasTrialDataLag
          ? lagDashboardDateByYear(lastStartDate, DATE_FORMAT_WITH_DAY)
          : lastStartDate,
      };

      return lookup;
    }),
    retry({ count: 3, delay: 1500 }),
    tap(() => {
      toggleStatusOnGlobalLoaderOrSkipOne(loaderKey);
      removeLoadingStatus(loaderKey);
    }),
    catchError((e) => {
      console.error('Get Defaults Error:', e);
      toggleStatusOnGlobalLoaderOrSkipOne(loaderKey);
      removeLoadingStatus(loaderKey);
      return throwError(() => e);
    })
  );
}

export function getStoredViewViewTypeDefaults(
  id: Views | ViewsForDefaultsOnly
) {
  const options = { ref: viewFilterDefaultEntitiesRef };
  const viewDefaults = filterStore.query(getEntity(id, options));
  return viewDefaults;
}

function internalGetViewDefault(
  viewFilterDefaultsNeeded: Observable<GetViewFilterDefaults>
): Observable<any> {
  return viewFilterDefaultsNeeded.pipe(
    debounceTime(300),
    skipUntil(persistFilterStore.initialized$.pipe(shareReplay(1))),
    switchMap((viewFilterDefaultsNeeded) => {
      const { view, viewType, presetView, viewFilters } =
        viewFilterDefaultsNeeded;

      const viewIdForDefault = `${view}${
        viewType ? `_${viewType}` : ''
      }` as ViewsForDefaultsOnly;

      return of({
        view,
        viewType,
        presetView,
        viewIdForDefault,
        viewFilters,
      }).pipe(
        viewFiltersDefaultsDataSource.trackRequestStatus({
          key: viewIdForDefault,
        }),
        viewFiltersDefaultsDataSource.skipWhileCached({
          key: viewIdForDefault,
        })
      ) as Observable<any>;
    }),
    switchMap(
      ({
        view,
        viewType,
        presetView,
        viewIdForDefault,
        viewFilters,
      }: {
        view: Views | ViewsForDefaultsOnly;
        viewType: ViewTypes;
        presetView: FilterSets;
        viewIdForDefault: ViewsForDefaultsOnly;
        viewFilters: FilterName[];
      }) => {
        return forkJoin({
          id: of(viewIdForDefault),
          filters: of(viewFilters),
          value: combineLatest(
            viewFilters.reduce<
              ViewFilterDefaultsValue$<FilterList | { error?: any }>
            >((result, filter) => {
              result[filter] = fetchViewFilterDefault(view, filter, viewType);
              return result;
            }, {} as ViewFilterDefaultsValue$)
          ).pipe(first()),
        }) as Observable<ViewFilterDefaults>;
      }
    ),
    tap((defaultEntry: ViewFilterDefaults & { value: { error?: any } }) => {
      const compactDefaults = createCompactDefaults(defaultEntry);

      if (!isEmpty(compactDefaults.value)) {
        addViewViewTypeDefault(compactDefaults);
      }
    })
  );
}

function internalGetViewMonthDefault(
  viewFilterDefaultsNeeded: Observable<GetViewDefaultMonth>
) {
  return viewFilterDefaultsNeeded.pipe(
    skipUntil(persistFilterStore.initialized$.pipe(shareReplay(1))),
    skipUntil(AUTH_CHECKED),
    distinctUntilKeyChanged('dateType'),
    switchMap(({ dateType }) => {
      return forkJoin({
        dateType: of({ value: dateType }),
        defaultDatesStored: of({
          [DefaultDates.DEFAULT_LAST_MONTH]:
            filterStore.query(getDefaultLastMonth),
          [DefaultDates.LAST_START_DATE]: filterStore.query(getLastStartDate),
        }),
      });
    }),
    switchMap(({ dateType, defaultDatesStored }) => {
      return forkJoin({
        data: iif<
          | {
              last_month?: string | undefined;
              old_last_month?: string | undefined;
              maxWeek?: string | undefined;
              start_date?: string | undefined;
              end_date?: string | undefined;
            }
          | Error,
          {
            last_month: string | undefined;
            maxWeek: string | undefined;
          }
        >(
          () =>
            !defaultDatesStored[DefaultDates.DEFAULT_LAST_MONTH] ||
            !defaultDatesStored[DefaultDates.LAST_START_DATE],
          queryViewFilterDefaultMonth(),
          of(defaultDatesStored)
        ),
        dateType: of({ value: dateType.value }),
      });
    }),
    tap(({ data }) => {
      if (data instanceof Error) {
        return;
      }

      const lastStartDate = data[DefaultDates.LAST_START_DATE];
      const defaultLastMonth = data[DefaultDates.DEFAULT_LAST_MONTH];
      const currentLastMonth = filterStore.query(getDefaultLastMonth);
      const currentLastStartDate = filterStore.query(getLastStartDate);
      if (lastStartDate && lastStartDate !== currentLastStartDate) {
        filterStore.update(setLastStartDate(lastStartDate));
      }
      if (defaultLastMonth && defaultLastMonth !== currentLastMonth) {
        filterStore.update(setDefaultLastMonth(defaultLastMonth));
      }
      setLastDateReady(true);
    })
  );
}

export const getViewDefault = createOneTimeEffectFn(internalGetViewDefault);
export const getViewDefaultMonth = createOneTimeEffectFn(
  internalGetViewMonthDefault
);
// export const getViewDefault = createEffectFn<GetViewFilterDefaults>(internalGetViewDefault);
