/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  Text,
  Icon,
  Flex,
  Image,
  Box,
  Button,
  PopoverCloseButton,
  useDisclosure,
  Link,
  useToast,
  PopoverArrow,
} from '@chakra-ui/react';
import {
  CTAInteractionTrackingEvents,
  PopoverTrigger,
  setIsVideoOpen,
  setVideoId,
  tourStore,
} from '@revelio/core';
import { FiBookOpen } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import { useEffect, useMemo, useState } from 'react';
import { useFreeTrialAlertModalState } from '../../hooks/useFreeTrialAlertModalState';
import overviewImage from './tutorial-thumbnails/tutorial-overview.jpg';
import compositionImage from './tutorial-thumbnails/tutorial-compositions.jpg';
import transitionsImage from './tutorial-thumbnails/tutorial-transitions.jpg';
import postingsImage from './tutorial-thumbnails/tutorial-postings.jpg';
import payImage from './tutorial-thumbnails/tutorial-pay.jpg';
import sentimentImage from './tutorial-thumbnails/tutorial-sentiment.jpg';
import discoveryImage from './tutorial-thumbnails/tutorial-discovery.jpg';
import talentDiscoveryImage from './tutorial-thumbnails/tutorial-talent-discovery.jpg';
import reportBuilderImage from './tutorial-thumbnails/tutorial-report-builder.jpg';
import dataBuilderImage from './tutorial-thumbnails/tutorial-data-builder.jpg';
import dataEnrichmentImage from './tutorial-thumbnails/tutorial-data-enrichment.jpg';
import {
  ACCOUNT_PORTAL_BACKEND_ROOT,
  fetchAccountPortalbackend,
  MetadataKey,
  useGetLoggedInUser,
} from '@revelio/auth';
import mixpanel from 'mixpanel-browser';
import { isToday, startOfTomorrow } from 'date-fns';
import { KeyValueResp } from '@revelio/data-access';
import { useCookie } from 'react-use';

export const videoMetadata: any = [
  {
    category: 'Dashboard Pages',
    videos: [
      {
        name: 'Dashboard Overview',
        description:
          'Analyze workforces through the lens of Companies, Geographies, Roles, and People.',
        duration: '2 mins',
        thumbnail: overviewImage,
        mediaId: 'vxukqgd7v9',
        paths: ['/'],
        id: '0',
      },
      {
        name: 'Overview',
        description:
          'Review a high-level summary of an organization, geography, or role and its talent composition and growth rates.',
        duration: '2 mins',
        thumbnail: overviewImage,
        mediaId: 'ei8r9m9c82',
        paths: ['/company/summary', '/geography/summary', '/role/summary'],
        id: '11',
      },
      {
        name: 'Compositions',
        description:
          'Benchmark workforces and gather competitive intelligence.',
        duration: '1 min',
        thumbnail: compositionImage,
        mediaId: 'zhuzvcgm1n',
        paths: [
          '/company/compositions',
          '/geography/compositions',
          '/role/compositions',
        ],
        id: '1',
      },
      {
        name: 'Transitions',
        description: 'See where talent is coming from and going to.',
        duration: '1 min',
        thumbnail: transitionsImage,
        mediaId: 'l44r5f542f',
        paths: [
          '/company/transitions',
          '/geography/transitions',
          '/role/transitions',
        ],
        id: '2',
      },
      {
        name: 'Job Postings',
        description: 'Analyze demand for roles and skill.',
        duration: '1 min',
        thumbnail: postingsImage,
        mediaId: 'etp6wqmphy',
        paths: ['/company/postings', '/geography/postings', '/role/postings'],
        id: '3',
      },
      {
        name: 'Pay',
        description: 'Benchmark salary across companies, locations, and roles.',
        duration: '2 mins',
        thumbnail: payImage,
        mediaId: 'uuzhueff2l',
        paths: ['/company/pay', '/geography/pay', '/role/pay'],
        id: '4',
      },
      {
        name: 'Sentiment',
        description:
          'Reveal factors of hiring and attrition for specific workforces.',
        duration: '1 min',
        thumbnail: sentimentImage,
        mediaId: 'c5pz9z6vca',
        paths: [
          '/company/sentiment',
          '/geography/sentiment',
          '/role/sentiment',
        ],
        id: '5',
      },
      {
        name: 'Talent Discovery',
        description:
          'Discover individual candidates matching your unique search criteria.',
        duration: '3 mins',
        thumbnail: talentDiscoveryImage,
        mediaId: '5o614nymsh',
        paths: ['/talent-discovery'],
        id: '6',
      },
      {
        name: 'Discovery',
        description:
          'Discover companies, roles, and talent markets by growth rate, attrition rate, skill density, and more.',
        duration: '3 mins',
        thumbnail: discoveryImage,
        mediaId: 'xffre1dkvc',
        paths: ['/company/screener', '/geography/screener', '/role/screener'],
        id: '7',
      },
    ],
  },
  {
    category: 'Custom Reports & Datasets',
    videos: [
      {
        name: 'Report Builder',
        description:
          'Build a custom report from our template library of workforce planning use cases.',
        duration: '1 min',
        thumbnail: reportBuilderImage,
        mediaId: '6dou1fxe9r',
        paths: [
          '/report-builder/report_type',
          '/reports',
          '/reports/',
          '/report-builder/',
        ],
        id: '8',
      },
      {
        name: 'Data Builder',
        description:
          'Create a custom dataset for your analysis. This advanced feature is available for trials with our sales team.',
        duration: '4 mins',
        thumbnail: dataBuilderImage,
        mediaId: 'itlzpu7eyb',
        paths: [
          '/deliverables/dataset',
          '/deliverables/status',
          '/deliverables/',
        ],
        id: '9',
      },
      {
        name: 'Data Enrichment',
        description:
          'Enrich your internal data. This advanced feature is available for trials with our sales team.',
        duration: '1 min',
        thumbnail: dataEnrichmentImage,
        mediaId: 'oqbg209tqf',
        paths: ['/deliverables/data-enrichment'],
        id: '10',
      },
    ],
  },
];

/* eslint-disable-next-line */
export interface TutorialButtonProps {}

const TutorialButton = (props: TutorialButtonProps) => {
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  const location = useLocation();
  const { showTrialChip } = useFreeTrialAlertModalState();
  const [autoOpen, setAutoOpen] = useState(false);
  const [wasAutoOpened, setWasAutoOpened] = useState(false);

  const toast = useToast();
  const { loggedInUser } = useGetLoggedInUser();
  const { name, email } = loggedInUser;
  const { days } = useFreeTrialAlertModalState();

  const { metadata } = loggedInUser;

  const [openedTutorials, updateOpenedTutorials] =
    useCookie('opened-tutorials');

  const userWatchedIds = useMemo(() => {
    return new Set(
      JSON.parse(
        metadata?.find(
          (item: KeyValueResp | null) =>
            item?.key === MetadataKey.StepsCompleted
        )?.value || '[]'
      )
    );
  }, [metadata]);

  const mixpanelContactSales = () => {
    mixpanel.track(CTAInteractionTrackingEvents.CONTACT_SALES_CLICK, {
      days_left: days,
    });
  };

  const handleContactSales = async () => {
    mixpanelContactSales();

    const contactedSales = localStorage.getItem('contacted-sales') || '';
    if (!contactedSales || !isToday(new Date(contactedSales))) {
      await fetchAccountPortalbackend(
        `${ACCOUNT_PORTAL_BACKEND_ROOT}/users/zoho/contactsales`,
        {
          method: 'POST',
          body: JSON.stringify({ email: email, name: name }),
        }
      );
      localStorage.setItem('contacted-sales', new Date().toDateString());
      toast({
        title: 'E-mail sent!',
        description:
          "We've sent an e-mail to both you and a member of our Sales Team to connect.",
        status: 'success',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      toast({
        title: 'Thank you!',
        description:
          'We’ve notified a member of our Sales Team, who will reach out shortly.',
        status: 'success',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const getVideoDataFromPath = (pathname: string) => {
    for (const category of videoMetadata) {
      const video = category.videos.find((v: any) =>
        v.paths.includes(pathname)
      );
      if (video) {
        return { ...video };
      }
    }
    console.error('No video found for this pathname:', pathname);
    return null;
  };

  const videoData = (() => {
    if (location.pathname.startsWith('/reports/')) {
      return getVideoDataFromPath('/reports/');
    }
    if (
      location.pathname.startsWith('/deliverables/') &&
      location.pathname !== '/deliverables/data-enrichment'
    ) {
      return getVideoDataFromPath('/deliverables/');
    }
    if (location.pathname.startsWith('/report-builder/')) {
      return getVideoDataFromPath('/report-builder/');
    }
    if (location.pathname.startsWith('/sample-report')) {
      return getVideoDataFromPath('/report-builder/');
    }
    return getVideoDataFromPath(location.pathname);
  })();

  useEffect(() => {
    if (!userWatchedIds.has(videoData?.id)) {
      setAutoOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const openedTutorialsArray = JSON.parse(openedTutorials || '[]');
    if (
      showTrialChip &&
      autoOpen &&
      !openedTutorialsArray.includes(videoData?.id)
    ) {
      onOpen();
      setWasAutoOpened(true);
      const nextDayStart = startOfTomorrow();
      updateOpenedTutorials(
        JSON.stringify([...openedTutorialsArray, videoData?.id]),
        { expires: nextDayStart }
      );
    }
  }, [
    showTrialChip,
    location.pathname,
    onOpen,
    autoOpen,
    updateOpenedTutorials,
    openedTutorials,
    videoData,
  ]);

  useEffect(() => {
    if (
      showTrialChip &&
      isOpen &&
      wasAutoOpened &&
      !['/deliverables/dataset', '/deliverables/data-enrichment'].includes(
        location.pathname
      ) &&
      autoOpen
    ) {
      const timer = setTimeout(() => {
        onClose();
      }, 6000);

      return () => clearTimeout(timer);
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTrialChip, isOpen, onClose, location.pathname, wasAutoOpened]);

  const handleClick = () => {
    onClose();
    tourStore.update(setVideoId(videoData?.id));
    tourStore.update(setIsVideoOpen(true));
  };

  const handleManualToggle = () => {
    setWasAutoOpened(false);
    onToggle();
  };

  return (
    <Popover
      placement="bottom-end"
      variant="with-shadow"
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setAutoOpen(false);
      }}
      arrowSize={15}
      isLazy
    >
      <PopoverTrigger>
        <Box
          position="relative"
          display="inline-block"
          data-testid="tutorial-icon-button"
        >
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="open notifications"
            colorScheme="navyBlueAlpha"
            icon={<Icon as={FiBookOpen} boxSize={5} color="#8F9CB2" />}
            onClick={handleManualToggle}
          />
          {!userWatchedIds.has(videoData?.id) && (
            <Box
              width="11px"
              height="11px"
              border="2px solid #ebeef3"
              bgGradient="linear(to-r, #FF4567, #FF4567)"
              position="absolute"
              top="1px"
              right="1px"
              borderRadius="full"
              pointerEvents="none"
              zIndex="0"
            />
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent
        sx={{
          _focus: { boxShadow: 'none', outline: 'none' },
          _focusVisible: { boxShadow: 'none', outline: 'none' },
        }}
        variants={{
          exit: {
            scale: 0,
            opacity: 0,
          },
          enter: {
            scale: 1,
            opacity: 1,
          },
        }}
      >
        <PopoverArrow />
        <PopoverBody maxH="360px" pb={3}>
          <Flex direction="column" gap={2} pt={6}>
            <Box
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
              onClick={handleClick}
            >
              <Image
                src={videoData?.thumbnail}
                alt="video thumbnail"
                rounded="xl"
              />
            </Box>
            <Text fontSize="md" fontWeight="semibold" px={1} lineHeight="16px">
              {videoData?.name}
            </Text>
            <Text fontSize="sm" px={1} lineHeight="16px">
              {videoData?.description}
              {videoData?.id === '10' && (
                <span>
                  {` `}
                  <Link
                    onClick={handleContactSales}
                    color="green.500"
                    fontWeight={600}
                  >
                    Contact Sales
                  </Link>{' '}
                  to get started.
                </span>
              )}
            </Text>
            <Button
              size="lg"
              mt={2}
              colorScheme="navyBlue"
              onClick={handleClick}
            >
              Watch Tutorial
              <span
                style={{
                  fontSize: '16px',
                  opacity: 0.7,
                  paddingLeft: '6px',
                  fontWeight: 500,
                }}
              >
                {videoData?.duration}
              </span>
            </Button>
          </Flex>
        </PopoverBody>
        <PopoverCloseButton />
      </PopoverContent>
    </Popover>
  );
};

export default TutorialButton;
